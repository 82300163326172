import React from 'react';
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Link,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FORUM } from '../../../../src/helpers/urlConstants';
import Phone from './icons/phone.svg';
import Email from './icons/email.svg';

const ContainerStyled = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(18),
  marginBottom: theme.spacing(30.5),

  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(25.5),
  },
}));

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

const DividerStyled = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.border?.main,
  margin: theme.spacing(12, 0),

  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(9, 0),
  },
}));

const MetaBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(4),
}));

const MetaImage = styled('img')({
  width: '56px !important',
  height: '56px !important',
  borderRadius: '50%',
});

const SupportWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  marginTop: theme.spacing(4),
}));

const SupportItemWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(4),
}));

export const Questions = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <ContainerStyled data-sectionid="questions">
      <Grid container justifyContent="center">
        <Grid item xs={12} md={4}>
          <Wrapper>
            <Typography variant="subtitle3" align="center" id="customer-support">
              {t('redesignCashbackPage.questions.title')}
            </Typography>
            <Typography
              variant="body1"
              color="textSecondary"
              align="center"
              sx={{
                marginBottom: theme.spacing(6),
                [theme.breakpoints.down('sm')]: {
                  marginBottom: theme.spacing(5),
                },
              }}
            >
              {t('redesignCashbackPage.questions.subtitle')}
            </Typography>
            <Button
              href={FORUM}
              variant="contained"
              color="primary"
              data-eventid="to-forum-from-faq"
            >
              {t('redesignCashbackPage.questions.button')}
            </Button>
          </Wrapper>
        </Grid>
      </Grid>

      <Grid container justifyContent="center">
        <Grid item xs={12} md={6}>
          <DividerStyled />
          <MetaBlock>
            <MetaImage src="/images/features/landingMain/patric.png" />
            <div>
              <Typography variant="subtitle2bold">
                {t('redesignCashbackPage.questions.help.name')}
              </Typography>
              <Typography variant="text" color="textSecondary">
                {t('redesignCashbackPage.questions.help.description')}
              </Typography>
              <SupportWrapper>
                <SupportItemWrapper>
                  <Box flexShrink={0} display="flex" alignItems="center">
                    <Phone width="24px" height="24px" />
                  </Box>
                  <div>
                    <Link href={`tel:${t('customerService.phoneLink')}`} underline="hover">
                      <Typography variant="body1" color="textPrimary">
                        {t('customerService.phone')}
                      </Typography>
                    </Link>
                    <Typography variant="body2" color="textSecondary" fontWeight={400}>
                      {t('customerService.supportTime')}
                    </Typography>
                  </div>
                </SupportItemWrapper>
                <SupportItemWrapper>
                  <Box flexShrink={0} display="flex" alignItems="center">
                    <Email width="24px" height="24px" />
                  </Box>
                  <Link href={`mailto:${t('customerService.email')}`} underline="hover">
                    <Typography variant="body1" color="textPrimary">
                      {t('customerService.email')}
                    </Typography>
                  </Link>
                </SupportItemWrapper>
              </SupportWrapper>
            </div>
          </MetaBlock>
        </Grid>
      </Grid>
    </ContainerStyled>
  );
};
